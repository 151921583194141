<template>
    <section class="informacion-terminos-condiciones control-old overflow-auto custom-scroll">
        <div v-for="(termino,index) in terminos" :key="index" class="row mx-0 mb-4">
            <p class="col-12 f-17 f-500 mb-2">{{ termino.titulo }}</p>
            <p class="col-12 f-15 lh-18" style="white-space:pre-line;">{{ _.get(termino.texto,'texto','Sin información') }}</p>
        </div>
    </section>
</template>

<script>
import Faqs from '@/services/faqs/faqs'
export default {
    data(){
        return {
            terminos: []
        }
    },
    mounted(){
        this.getTerminos()
    },
    methods: {
        async getTerminos(){
            try {
                let tipo = 2
                const { data } = await Faqs.listar({tipo})
                this.terminos = data.data.faqs
            } catch (error){
                this.errorCatch(error)
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.informacion-terminos-condiciones{
    height: 90vh;
}
</style>
